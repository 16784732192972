<div class="wrapper" style="height: auto; overflow: hidden;cursor: pointer;">
  <div class="sidebar-wrapper" data-simplebar="true">
    <div class="sidebar-menu-wrapper">
      <div class="sidebar-header">
        <div>
          <img src="../../assets/img/samvaad_tutor_logo.png" class="logo-icon" alt="logo icon">
        </div>

      </div>
      <div class="wrapper" style="margin-top: 9.6vh">
        <ul class="metismenu" id="menu">

          <li style="padding: 0px;" class="parent mm-active"><a [routerLink]="['/HOME/dashboard']"><span class="icon">
                <svg width="15" height="20" fill="#A3AED0" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.944444 9.44444H6.61111C7.13056 9.44444 7.55556 9.01944 7.55556 8.5V0.944444C7.55556 0.425 7.13056 0 6.61111 0H0.944444C0.425 0 0 0.425 0 0.944444V8.5C0 9.01944 0.425 9.44444 0.944444 9.44444ZM0.944444 17H6.61111C7.13056 17 7.55556 16.575 7.55556 16.0556V12.2778C7.55556 11.7583 7.13056 11.3333 6.61111 11.3333H0.944444C0.425 11.3333 0 11.7583 0 12.2778V16.0556C0 16.575 0.425 17 0.944444 17ZM10.3889 17H16.0556C16.575 17 17 16.575 17 16.0556V8.5C17 7.98056 16.575 7.55556 16.0556 7.55556H10.3889C9.86944 7.55556 9.44444 7.98056 9.44444 8.5V16.0556C9.44444 16.575 9.86944 17 10.3889 17ZM9.44444 0.944444V4.72222C9.44444 5.24167 9.86944 5.66667 10.3889 5.66667H16.0556C16.575 5.66667 17 5.24167 17 4.72222V0.944444C17 0.425 16.575 0 16.0556 0H10.3889C9.86944 0 9.44444 0.425 9.44444 0.944444Z" />
                </svg></span>&nbsp;&nbsp;<span class="text">Dashboard</span></a>
          </li>

          <li *ngIf="roleId==1" style="padding: 0px;" class="parent"><a (click)="smartHr()"><span class="icon"><i
                  class="fas fa-users"></i></span>&nbsp;&nbsp;<span class="text">SMHR</span></a>
          </li>


          <li style="padding: 0px;" class="parent" *ngFor='let item of menus'><a
              [ngClass]="item.childs.length > 0 ? 'has-arrow' : ''" (click)="navigate(item.path,item)"><span
                class="icon" [attr.href]="'#'+getId(item.Name)"><i class={{item.CssClass}}></i></span>&nbsp;&nbsp;<span
                class="text">{{item.Name}}</span></a>
            <div class="wrapper">
              <ul style="padding: 0px;" class="style-1 shadow  nav-active2 parent" *ngIf="item.childs.length"
                [attr.id]="getId(item.Name)">
                <li class="parent" *ngFor="let subMenu of item.childs"><a
                    [ngClass]="subMenu.childs.length > 0 ? 'has-arrow' : ''"
                    (click)="navigate(subMenu.path,subMenu)">{{subMenu.Name}}</a>
                  <div class="wrapper">
                    <ul class="style-1 shadow  nav-active3" *ngIf='subMenu.childs.length'>
                      <li class="parent" *ngFor="let menu of subMenu.childs"><a
                          [ngClass]="menu.childs.length > 0 ? 'has-arrow' : ''"
                          (click)="navigate(menu.path,menu)">{{menu.Name}}</a>
                        <div class="wrapper">
                          <ul class="style-1 shadow  nav-active4" *ngIf='menu.childs.length'>
                            <li *ngFor="let fourthMenu of menu.childs"><a
                                [ngClass]="fourthMenu.childs.length > 0 ? 'has-arrow' : ''"
                                (click)="navigate(fourthMenu.path,fourthMenu)">{{fourthMenu.Name}}</a>
                              <div class="wrapper">
                                <ul class="style-1 shadow  nav-active4" *ngIf='fourthMenu.childs.length'>
                                  <li *ngFor="let fifthMenu of fourthMenu.childs"><a
                                      [ngClass]="fifthMenu.childs.length > 0 ? 'has-arrow' : ''"
                                      (click)="navigate(fifthMenu.path,fifthMenu)">{{fifthMenu.Name}}</a></li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>

                    </ul>
                  </div>
                </li>

              </ul>
            </div>
          </li>


        </ul>
      </div>
    </div>
  </div>

</div>


<!-- <div class="wrapper" style="height: auto; overflow: hidden;cursor: pointer;">
  <div class="sidebar-wrapper" data-simplebar="true">
    <div class="sidebar-header">
      <div>
        <img src="../../assets/img/samvaad_tutor_logo.png" class="logo-icon" alt="logo icon">
      </div>
    </div>
    <ul class="metismenu" id="menu">
      <li class="mm-active">
        <a [routerLink]="['/HOME/dashboard']">
          <div class="parent">
            <svg width="17" height="17" fill="#A3AED0" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.944444 9.44444H6.61111C7.13056 9.44444 7.55556 9.01944 7.55556 8.5V0.944444C7.55556 0.425 7.13056 0 6.61111 0H0.944444C0.425 0 0 0.425 0 0.944444V8.5C0 9.01944 0.425 9.44444 0.944444 9.44444ZM0.944444 17H6.61111C7.13056 17 7.55556 16.575 7.55556 16.0556V12.2778C7.55556 11.7583 7.13056 11.3333 6.61111 11.3333H0.944444C0.425 11.3333 0 11.7583 0 12.2778V16.0556C0 16.575 0.425 17 0.944444 17ZM10.3889 17H16.0556C16.575 17 17 16.575 17 16.0556V8.5C17 7.98056 16.575 7.55556 16.0556 7.55556H10.3889C9.86944 7.55556 9.44444 7.98056 9.44444 8.5V16.0556C9.44444 16.575 9.86944 17 10.3889 17ZM9.44444 0.944444V4.72222C9.44444 5.24167 9.86944 5.66667 10.3889 5.66667H16.0556C16.575 5.66667 17 5.24167 17 4.72222V0.944444C17 0.425 16.575 0 16.0556 0H10.3889C9.86944 0 9.44444 0.425 9.44444 0.944444Z" />
            </svg>

          </div>
          <div class="menu-title">Dashboard</div>
        </a>
      </li>
      <li>
        <a (click)="smartHr()">
          <div class="parent">
            <span class="icon"><i class="fas fa-users"></i></span>
          </div>
          <div class="menu-title">SMHR</div>
        </a>
      </li>

      <li style="padding: 0px;" class="parent" *ngFor='let item of menus'>
        <a [ngClass]="item.childs.length > 0 ? 'has-arrow' : ''" (click)="navigate(item.path,item)">
          <div class="parent">
            <span class="icon" [attr.href]="'#'+getId(item.Name)"><i class={{item.CssClass}}></i></span>
          </div>
          <div class="menu-title">{{item.Name}}</div>
        </a>
        <div class="wrapper">
          <ul style="padding: 0px;" class="style-1 shadow  nav-active2 parent" *ngIf="item.childs.length"
            [attr.id]="getId(item.Name)">
            <li class="parent" *ngFor="let subMenu of item.childs"><a
                [ngClass]="subMenu.childs.length > 0 ? 'has-arrow' : ''"
                (click)="navigate(subMenu.path,subMenu)">{{subMenu.Name}}</a>
              <div class="wrapper">
                <ul class="style-1 shadow  nav-active3" *ngIf='subMenu.childs.length'>
                  <li class="parent" *ngFor="let menu of subMenu.childs"><a
                      [ngClass]="menu.childs.length > 0 ? 'has-arrow' : ''"
                      (click)="navigate(menu.path,menu)">{{menu.Name}}</a>
                    <div class="wrapper">
                      <ul class="style-1 shadow  nav-active4" *ngIf='menu.childs.length'>
                        <li *ngFor="let fourthMenu of menu.childs"><a
                            [ngClass]="fourthMenu.childs.length > 0 ? 'has-arrow' : ''"
                            (click)="navigate(fourthMenu.path,fourthMenu)">{{fourthMenu.Name}}</a>
                          <div class="wrapper">
                            <ul class="style-1 shadow  nav-active4" *ngIf='fourthMenu.childs.length'>
                              <li *ngFor="let fifthMenu of fourthMenu.childs"><a
                                  [ngClass]="fifthMenu.childs.length > 0 ? 'has-arrow' : ''"
                                  (click)="navigate(fifthMenu.path,fifthMenu)">{{fifthMenu.Name}}</a></li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>

                </ul>
              </div>
            </li>

          </ul>
        </div>
      </li>
    </ul>

    <div class="ms-1 sidebar-footer border-top pb-4 pt-3 px-4 w-100" style=" position: absolute; bottom: 0;">
      <div>
        <ul>
          <li>
            <a class="d-flex fw-500" href="payment.html" style="color: #8291C0;">
              <div class="parent">

                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                  class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                  <path fill-rule="evenodd"
                    d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                </svg>


              </div>
              <div class="menu-title ms-3">Log Out</div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div> -->