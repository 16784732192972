<!-- <nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
  <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center  pt-2 pb-2">
    <a class="brand-logo" href="index.html"><img src="../../assets/images/dhanush/logo-sam.png" alt="logo" class="" /></a>
   <a class="navbar-brand brand-logo-mini" href="index.html">LMS</a> 
  </div>

  <div class="navbar-menu-wrapper d-flex align-items-stretch"> -->


<!-- <button class="navbar-toggler navbar-toggler align-self-center" (click)="menuClick()" type="button" data-toggle="minimize">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-justify" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
      </svg>
      
    </button> -->


<!-- <span class="align-self-center pl-3 pr-3 d-none d-sm-none d-md-none d-lg-block">
      <a class="brand-logo" href="index.html"><img src="../../assets/images/dhanush/logo-sam.png" alt="logo" class="" /></a>
    </span>
    <span class="align-self-center d-none d-sm-none d-md-block">
      <h3 style="font-size:22px; font-weight:500;"> Learning Management System</h3>
    </span>
    
    <ul class="navbar-nav navbar-nav-right">
      <div class="always-here" id="google_translate_element"></div>
     
     
      <li class="nav-item dropdown" *ngIf="false">
        <a class="nav-link count-indicator dropdown-toggle" id="messageDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chat-left-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
          </svg>
          <span class="count-symbol bg-warning"></span>
        </a>
        <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="messageDropdown">
          <h6 class="p-3 mb-0">Messages</h6>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item preview-item">
            <div class="preview-thumbnail">
              <img src="assets/images/faces/face4.jpg" alt="image" class="profile-pic">
            </div>
            <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
              <h6 class="preview-subject ellipsis mb-1 font-weight-normal">Mark send you a message</h6>
              <p class="text-gray mb-0"> 1 Minutes ago </p>
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item preview-item">
            <div class="preview-thumbnail">
              <img src="assets/images/faces/face2.jpg" alt="image" class="profile-pic">
            </div>
            <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
              <h6 class="preview-subject ellipsis mb-1 font-weight-normal">Cregh send you a message</h6>
              <p class="text-gray mb-0"> 15 Minutes ago </p>
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item preview-item">
            <div class="preview-thumbnail">
              <img src="assets/images/faces/face3.jpg" alt="image" class="profile-pic">
            </div>
            <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
              <h6 class="preview-subject ellipsis mb-1 font-weight-normal">Profile picture updated</h6>
              <p class="text-gray mb-0"> 18 Minutes ago </p>
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <h6 class="p-3 mb-0 text-center">4 new messages</h6>
        </div>
      </li>
      <li class="nav-item dropdown"  *ngIf="false">
        <a class="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-bell" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2z"/>
            <path fill-rule="evenodd" d="M8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
          </svg>
          <span class="count-symbol bg-danger"></span>
        </a>
        <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
          <h6 class="p-3 mb-0">Notifications</h6>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item preview-item">
            <div class="preview-thumbnail">
              <div class="preview-icon bg-success">
                <i class="mdi mdi-calendar"></i>
              </div>
            </div>
            <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
              <h6 class="preview-subject font-weight-normal mb-1">Event today</h6>
              <p class="text-gray ellipsis mb-0"> Just a reminder that you have an event today </p>
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item preview-item">
            <div class="preview-thumbnail">
              <div class="preview-icon bg-warning">
                <i class="mdi mdi-settings"></i>
              </div>
            </div>
            <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
              <h6 class="preview-subject font-weight-normal mb-1">Settings</h6>
              <p class="text-gray ellipsis mb-0"> Update dashboard </p>
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item preview-item">
            <div class="preview-thumbnail">
              <div class="preview-icon bg-info">
                <i class="mdi mdi-link-variant"></i>
              </div>
            </div>
            <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
              <h6 class="preview-subject font-weight-normal mb-1">Launch Admin</h6>
              <p class="text-gray ellipsis mb-0"> New admin wow! </p>
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <h6 class="p-3 mb-0 text-center">See all notifications</h6>
        </div>
      </li>
   
      <li class="nav-item nav-profile dropdown">
        <a class="nav-link dropdown-toggle" id="profileDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
          <div class="nav-profile-img">
            <img src="assets/images/faces/face1.jpg" alt="image">
            <span class="availability-status online"></span>
          </div>
          <div class="nav-profile-text">
            <p class="mb-1 text-black">{{userName}} <span>
              <svg width="0.500rem" height="0.500rem" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
            </span></p>
          </div>
        </a>
        <div class="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">
          <a class="dropdown-item" href="#" *ngIf="false">
            <i class="mdi mdi-cached mr-2 text-success"></i> Activity Log </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item"  (click)="Signout()">
            <i class="mdi mdi-logout mr-2 text-primary"></i> Signout </a>
        </div>
      </li>
    </ul>
    <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
      <span class="mdi mdi-menu"></span>
    </button>
  </div>
</nav> -->

















<header>
  <div class="topbar d-flex align-items-center">
    <nav class="navbar navbar-expand ps-0">
      <nav aria-label="breadcrumb" class="d-none d-sm-none d-md-block">
        <!-- <div class="toggle-icon ms-auto"><i class='bx bx-menu text-white'
            style="background-color: #eb5a05; line-height: 1;border-radius:2px; "></i>
        </div> -->

        <div class="ms-auto menu-toggle toggle-icon"><i class='bx bx-menu text-white'
            style="background-color: #D01616; line-height: 1;border-radius:2px; "></i>
        </div>
        <!-- <button  class="btn btn-sm menu-toggle">Toggle</button> -->
        <!-- <a href="#menu-toggle" class="btn btn-default menu-toggle">Toggle Menu</a> -->
        <ol class="breadcrumb mb-0 p-0 d-none">
          <li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
          </li>
          <li class="breadcrumb-item active" style="font-size: 15px;" aria-current="page">Dashboard</li>
        </ol>
      </nav>
      <div class="mobile-toggle-menu"><i class='bx bx-menu'></i>
      </div>

      <div class="search-bar flex-grow-1">
        <div class="position-relative search-bar-box">
          <input type="text" class="form-control search-control" placeholder="Type to search..."> <span
            class="position-absolute top-50 search-show translate-middle-y"><i class='bx bx-search'></i></span>
          <span class="position-absolute top-50 search-close translate-middle-y"><i class='bx bx-x'></i></span>
        </div>
      </div>
      <div class="text-center m-auto">
        <h4 style="font-family: 'Georgia', Times, serif;color: #bb2024;"> <b>The Open University Of Kenya</b> </h4>
      </div>
      <div class="top-menu ms-auto">
        <ul class="navbar-nav align-items-center">
          <!-- <li class="nav-item mobile-search-icon">
            <a class="nav-link" href="#"> <i class='fas fa-comment-dots'></i>
            </a>
          </li> -->
         
          <!-- <li class="nav-item dropdown" id="google_translate_element">
            <select class="form-select form-select-sm" aria-label=".form-select-sm example">
              <option selected>Select language</option>
              <option value="1">English</option>
              <option value="2">Hindi</option>
              <option value="3">Telugu</option>
            </select>
          </li> -->
          <!-- <li class="nav-item dropdown dropdown-large">
              <a class="nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">	<i class='bx bx-category'></i>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <div class="row row-cols-3 g-3 p-3">
                  <div class="col text-center">
                    <div class="app-box mx-auto bg-gradient-cosmic text-white"><i class='bx bx-group'></i>
                    </div>
                    <div class="app-title">Teams</div>
                  </div>
                  <div class="col text-center">
                    <div class="app-box mx-auto bg-gradient-burning text-white"><i class='bx bx-atom'></i>
                    </div>
                    <div class="app-title">Projects</div>
                  </div>
                  <div class="col text-center">
                    <div class="app-box mx-auto bg-gradient-lush text-white"><i class='bx bx-shield'></i>
                    </div>
                    <div class="app-title">Tasks</div>
                  </div>
                  <div class="col text-center">
                    <div class="app-box mx-auto bg-gradient-kyoto text-dark"><i class='bx bx-notification'></i>
                    </div>
                    <div class="app-title">Feeds</div>
                  </div>
                  <div class="col text-center">
                    <div class="app-box mx-auto bg-gradient-blues text-dark"><i class='bx bx-file'></i>
                    </div>
                    <div class="app-title">Files</div>
                  </div>
                  <div class="col text-center">
                    <div class="app-box mx-auto bg-gradient-moonlit text-white"><i class='bx bx-filter-alt'></i>
                    </div>
                    <div class="app-title">Alerts</div>
                  </div>
                </div>
              </div>
            </li>  -->
          <app-online-teachers *ngIf="ROLEID=='3'"></app-online-teachers>
          <app-load-chat-data *ngIf="ROLEID=='3' || ROLEID=='2'"></app-load-chat-data>
          <li class="nav-item dropdown dropdown-large">
            <a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button"
              data-bs-toggle="dropdown" aria-expanded="false"> <span
                class="alert-count">{{getUnViewdNotificationCount()}}</span>
                <i class='bx bxs-bell'></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <a href="javascript:;">
                <div class="msg-header">
                  <p class="msg-header-title">Notifications</p>
                  <!-- <p class="msg-header-clear ms-auto">Marks all as read</p> -->
                </div>
              </a>
              <div style="overflow-y:auto; height:360px;">
                <div class="header-notifications-list" *ngFor="let item of notification">
                  <a class="dropdown-item" data-bs-toggle="modal"
                    (click)="viewNotification(item.internal_notification_id)" data-bs-target="#myNoti"
                    href="javascript:;">

                    <div class="d-flex align-items-center">
                      <div class="notify bg-light-primary text-primary"><i class="bx bx-group"></i>
                      </div>
                      <div>
                        <div class="flex-grow-1">

                          <!-- <h6 class="msg-name" id="message">{{item.notification_subject}}</h6>
                        <p class="msg-info">{{item.notification_type}}</p>
                        <p class="msg-info">{{item.notification_body}}</p> -->
                          <h6 class="msg-name html" id="message" [innerHTML]="item.notification_subject"></h6>
                          <p class="msg-info html" [innerHTML]="item.notification_type"></p>
                          <p class="msg-info html" [innerHTML]="item.notification_body"></p>
                        </div>
                      </div>

                    </div>
                  </a>
                  <!-- <a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="notify bg-light-danger text-danger"><i class="bx bx-cart-alt"></i>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">New Orders <span class="msg-time float-end">2 min
                          ago</span></h6>
                      <p class="msg-info">You have recived new orders</p>
                    </div>
                  </div>
                </a>
                < a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="notify bg-light-success text-success"><i class="bx bx-file"></i>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">24 PDF File<span class="msg-time float-end">19 min
                          ago</span></h6>
                      <p class="msg-info">The pdf files generated</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="notify bg-light-warning text-warning"><i class="bx bx-send"></i>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Time Response <span class="msg-time float-end">28 min
                          ago</span></h6>
                      <p class="msg-info">5.1 min avarage time response</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="notify bg-light-info text-info"><i class="bx bx-home-circle"></i>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">New Product Approved <span class="msg-time float-end">2 hrs
                          ago</span></h6>
                      <p class="msg-info">Your new product has approved</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="notify bg-light-danger text-danger"><i class="bx bx-message-detail"></i>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">New Comments <span class="msg-time float-end">4 hrs
                          ago</span></h6>
                      <p class="msg-info">New customer comments recived</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="notify bg-light-success text-success"><i class='bx bx-check-square'></i>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Your item is shipped <span class="msg-time float-end">5 hrs
                          ago</span></h6>
                      <p class="msg-info">Successfully shipped your item</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="notify bg-light-primary text-primary"><i class='bx bx-user-pin'></i>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">New 24 authors<span class="msg-time float-end">1 day
                          ago</span></h6>
                      <p class="msg-info">24 new authors joined last week</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="notify bg-light-warning text-warning"><i class='bx bx-door-open'></i>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Defense Alerts <span class="msg-time float-end">2 weeks
                          ago</span></h6>
                      <p class="msg-info">45% less alerts last 4 weeks</p>
                    </div>
                  </div>
                </a> -->
                </div>
              </div>
              <a href="javascript:;">
                <div class="text-center msg-footer" (click)="viewMessage('0')" data-bs-toggle="modal"
                  data-bs-target="#myNoti">View All</div>
              </a>
            </div>
          </li>

          <!-- ..........POPUP NOTIFICATION.............. -->


          <!-- <li class="nav-item dropdown dropdown-large">
            <a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button"
              data-bs-toggle="dropdown" aria-expanded="false"> <span class="alert-count">8</span>
              <i class='bx bx-comment'></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <a href="javascript:;">
                <div class="msg-header">
                  <p class="msg-header-title">Messages</p>
                  <p class="msg-header-clear ms-auto">Marks all as read</p>
                </div>
              </a>
              <div class="header-message-list">
                <a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="https://via.placeholder.com/110x110" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Daisy Anderson <span class="msg-time float-end">5 sec
                          ago</span></h6>
                      <p class="msg-info">The standard chunk of lorem</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="https://via.placeholder.com/110x110" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Althea Cabardo <span class="msg-time float-end">14
                          sec ago</span></h6>
                      <p class="msg-info">Many desktop publishing packages</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="https://via.placeholder.com/110x110" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Oscar Garner <span class="msg-time float-end">8 min
                          ago</span></h6>
                      <p class="msg-info">Various versions have evolved over</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="https://via.placeholder.com/110x110" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Katherine Pechon <span class="msg-time float-end">15
                          min ago</span></h6>
                      <p class="msg-info">Making this the first true generator</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="https://via.placeholder.com/110x110" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Amelia Doe <span class="msg-time float-end">22 min
                          ago</span></h6>
                      <p class="msg-info">Duis aute irure dolor in reprehenderit</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="https://via.placeholder.com/110x110" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Cristina Jhons <span class="msg-time float-end">2 hrs
                          ago</span></h6>
                      <p class="msg-info">The passage is attributed to an unknown</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="https://via.placeholder.com/110x110" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">James Caviness <span class="msg-time float-end">4 hrs
                          ago</span></h6>
                      <p class="msg-info">The point of using Lorem</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="https://via.placeholder.com/110x110" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Peter Costanzo <span class="msg-time float-end">6 hrs
                          ago</span></h6>
                      <p class="msg-info">It was popularised in the 1960s</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="https://via.placeholder.com/110x110" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">David Buckley <span class="msg-time float-end">2 hrs
                          ago</span></h6>
                      <p class="msg-info">Various versions have evolved over</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="https://via.placeholder.com/110x110" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Thomas Wheeler <span class="msg-time float-end">2 days
                          ago</span></h6>
                      <p class="msg-info">If you are going to use a passage</p>
                    </div>
                  </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                  <div class="d-flex align-items-center">
                    <div class="user-online">
                      <img src="https://via.placeholder.com/110x110" class="msg-avatar" alt="user avatar">
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="msg-name">Johnny Seitz <span class="msg-time float-end">5 days
                          ago</span></h6>
                      <p class="msg-info">All the Lorem Ipsum generators</p>
                    </div>
                  </div>
                </a>
              </div>
              <a href="javascript:;">
                <div class="text-center msg-footer">View All Messages</div>
              </a>
            </div>
          </li> -->
        </ul>
      </div>
      <div class="user-box dropdown">
        <a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button"
          data-bs-toggle="dropdown" aria-expanded="false">
          <img src="https://i.pinimg.com/736x/89/90/48/899048ab0cc455154006fdb9676964b3.jpg" class="user-img"
            alt="user avatar">
          <div class="user-info ps-3">
            <p class="user-name mb-0">{{userName}}</p>
            <p class="designattion mb-0">{{roleName}}</p>
          </div>
        </a>
        <ul class="dropdown-menu dropdown-menu-end">
          <li><a class="dropdown-item" href="javascript:;" [routerLink]="['/HOME/dashboard']"><i
                class='bx bx-home-circle'></i><span>Dashboard</span></a>
          </li>
          <li *ngIf="ROLEID == '1' || ROLEID == '2' || ROLEID == '3'" (click)="profileNavigate()"><a
              class="dropdown-item" href="javascript:;"><i class="bx bx-user"></i><span>Profile</span></a>
          </li>
          <!-- ROLEID == '1' || ROLEID == '2' || -->
          <li *ngIf=" ROLEID == '3'" (click)="userManual()"><a class="dropdown-item"
              href="javascript:;"><i class="far fa-file-pdf"></i><span>User Manual</span></a>
          </li>
          <li><a class="dropdown-item" href="javascript:;" data-bs-toggle="modal" data-bs-target="#changePassword">
              <i class="bx bx-key"></i><span>Change Password</span></a>
          </li>
          




          <li>
            <div class="dropdown-divider mb-0"></div>
          </li>
          <li><a class="dropdown-item" href="javascript:;" (click)="Signout()"><i
                class='bx bx-log-out-circle'></i><span>Logout</span></a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>




<div class="modal" id="myNoti" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-md">
    <div class="modal-content">

      <div class="modal-header">
        <h5 class="mb-0 w-100"> Notification</h5>

        <button type="button" (click)="close()" class="close" aria-label="Close" data-bs-dismiss="modal"><i
            class="fas fa-times"></i></button>
      </div>

      <div class="modal-body" *ngFor="let item of viewNoti">
        <!-- <div id="htmldiv" class="html" [innerHTML]="viewMailData.email_body"></div> -->
        <h4 class="msg-name html" id="htmldiv" [innerHTML]="item.notification_subject"></h4>
        <hr />
        <h6 class="msg-info html" id="htmldiv1" [innerHTML]="item.notification_type"></h6>
        <p class="msg-info html" id="htmldiv2" [innerHTML]="item.notification_body"></p>
      </div>

      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-outline-success" (click)="apply()" *ngIf="levelUpdate">Apply</button> -->
        <button type="button" class="btn btn-outline-danger" id="Noti_close" (click)="close()"
          data-bs-dismiss="modal">Close</button>

      </div>

    </div>
  </div>
</div>








<div class="modal" id="changePassword" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog  modal-lg">
    <div class="modal-content">

      <div class="modal-header py-2">
        <h4 class="modal-title w-100"> Change Password</h4>
        <button type="button" (click)="close1()" class="close" data-bs-dismiss="modal"><i
            class="fas fa-times"></i></button>
      </div>


      <div class="modal-body">
        <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="ChangePass(myForm)">
          <fieldset>
            <div class="form-group row">
              <label class="col-md-4 control-label line_2 rem_1">
                Old Password<span class="text-danger">*</span></label>
              <div class="col-md-8 inputGroupContainer">
                <div class="input-group">
                  <strong>:</strong> &nbsp;
                  <input placeholder="" type='password' class="form-control" formControlName="oldPassword">
                </div>
                <div *ngIf="myForm.get('oldPassword').touched">
                  <span class="text-danger" *ngIf="myForm.get('oldPassword').hasError('required')">
                    This field is required.
                  </span>
                  <span class="text-danger" *ngIf="myForm.get('oldPassword').hasError('minlength')">
                    password minimum 6 characters.
                  </span>
                </div>

              </div>
            </div>&nbsp;&nbsp;
            <div class="form-group row">
              <label class="col-md-4 control-label line_2 rem_1">
                New Password<span class="text-danger">*</span></label>
              <div class="col-md-8 inputGroupContainer">
                <div class="input-group">
                  <strong>:</strong> &nbsp;
                  <input placeholder="" type='password' class="form-control" formControlName="password">
                </div>
                <div *ngIf="myForm.get('password').touched">
                  <span class="text-danger" *ngIf="myForm.get('password').hasError('required')">
                    This field is required.
                  </span>
                  <span class="text-danger" *ngIf="myForm.get('password').hasError('minlength')">
                    password minimum 6 characters.
                  </span>
                </div>
              </div>
            </div>&nbsp;&nbsp;
            <div class="form-group row">
              <label class="col-md-4 control-label line_2 rem_1">
                Confirm Password<span class="text-danger">*</span></label>
              <div class="col-md-8 inputGroupContainer">
                <div class="input-group">
                  <strong>:</strong> &nbsp;
                  <input placeholder="" type='password' class="form-control" formControlName="confirmPassword"
                    (change)="passwordCheck()">
                </div>
                <div *ngIf="myForm.get('confirmPassword').touched">
                  <span class="text-danger" *ngIf="myForm.get('confirmPassword').hasError('required')">
                    This field is required.
                  </span>
                  <span class="text-danger" *ngIf="myForm.get('confirmPassword').hasError('minlength')">
                    password minimum 6 characters.
                  </span>
                </div>
              </div>
            </div>

          </fieldset>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn  btn-sm btn-save" (click)="ChangePass(myForm)"
          [disabled]="myForm.invalid">Change Password</button>
        <button type="button" class="btn btn-sm btn-danger" id='passPopupClose' (click)="close1()"
          data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>