<header class="mt-5">
    
    <div class="owl-carousel mainslider owl-theme  them1 mt-0">
        <div class="item" *ngFor="let sliders of mainSliderList">
            <img [src]="sliders.image" alt="images not found">
            <div class="cover">
                <div class="container">
                    <div class="header-content">
                        <h1 [innerHtml]="sliders.name"></h1>
                        <div class="line"></div>
                        <p class="mb-0" style="width:50%; color:white;font-weight: 400;">{{sliders.description}}
                        </p>
                        <img  src="./../../../assets/img/banner_img/Curve.png" 
                         class="img-fluid w-25 mt-3 mb-3">
                            <p class="mb-0" style="width:50%; color:#1E1E1E;font-weight: 500;">
                                Start now, register and explore for free
                            </p>
                            <!-- <a class="btn homeslider-btn px-xl-5 py-xl-3 px-1 py-1 mt-2">Enroll</a>    -->
                        <!-- <a class="btn homeslider-btn px-xl-5 py-xl-3 px-1 py-1 mt-2">Explore Path</a> -->
                        <!-- <h4>We help entrepreneurs, start-ups and enterprises shape their ideas into products</h4> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="item">
            <img src="assets/img/banner.png" alt="images not found">
            <div class="cover">
                <div class="container">
                    <div class="header-content">
                        <div class="line"></div>
                        <h1>Learn Any Skills to <br> advance your <br> career path</h1>

                        <p class="mb-0" style="width:50%; color:white;font-weight: 500;">Want to improve your work
                            skills? You
                            need to study harder with the help of a great mentor to improve your performance at work
                        </p>
                        <a class="btn homeslider-btn px-xl-5 py-xl-3 px-1 py-1  mt-2">Explore Path</a>
                      
                    </div>
                </div>
            </div>
        </div>
        <div class="item">
            <img src="assets/img/banner.png" alt="images not found">
            <div class="cover">
                <div class="container">
                    <div class="header-content">
                        <div class="line"></div>
                        <h1>Learn Any Skills to <br> advance your <br> career path</h1>

                        <p class="mb-0" style="width:50%; color:white;font-weight: 500;">Want to improve your work
                            skills? You
                            need to study harder with the help of a great mentor to improve your performance at work
                        </p>
                        <a class="btn homeslider-btn px-xl-5 py-xl-3 px-1 py-1 mt-2">Explore Path</a>
                        
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</header>