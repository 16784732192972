<section>
    <div class="">
        <div class="container-fluid pt-4 pb-4" style="background-color: #F2F4FF;">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12 pt-4 pb-4">
                        <div class="testimonials123 owl-carousel owl-theme ">
                            <div class="item" *ngFor="let monials of testMonialsList">

                                <div class="row" >
                                    <div class="col-xl-4">
                                        <img [src]="monials.image"
                                            class="img-fluid img-thumbnail">
                                    </div>
                                    <div class="col-xl-8 d-flex align-items-center">

                                        <div class=" p-3">
                                            <h2 style="font-weight:700;">
                                               {{monials.name}}
                                            </h2>
                                            <p class="samvaad-txt " style="color: #4D4D4D;">
                                                {{monials.description}}
                                            </p>
                                            <h5 class="mb-0">{{monials.name1}}</h5>
                                            <p>{{monials.designation}}</p>
                                        </div>
                                    </div>
                                </div>



                            </div>
                            <!-- <div class="item">
                                <div class="row">
                                    <div class="col-xl-4">
                                        <img src="../../../../assets/img/about-img.png"
                                            class="img-fluid img-thumbnail ">
                                    </div>
                                    <div class="col-xl-8 d-flex align-items-center">

                                        <div class="p-3">
                                            <h2 style="font-weight:700;">
                                                What did they say
                                            </h2>
                                            <p class="samvaad-txt " style="color: #4D4D4D;">
                                                Higher education in the era of the industrial revolution 4.0
                                                requires break through learning
                                                using digital platforms that answer the challenges of millennial
                                                students to study anywhere,
                                                anytime and with leading-edge ICT technology. From student
                                                recruitment to teaching and learning
                                                administration processes,
                                            </p>
                                            <h5>Brain Patton</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="row">
                                    <div class="col-xl-4">
                                        <img src="../../../../assets/img/aboutus-img.png"
                                            class="img-fluid img-thumbnail ">
                                    </div>
                                    <div class="col-xl-8 d-flex align-items-center">

                                        <div class="p-3">
                                            <h2 style="font-weight:700;">
                                                What did they say
                                            </h2>
                                            <p class="samvaad-txt " style="color: #4D4D4D;">
                                                Higher education in the era of the industrial revolution 4.0
                                                requires break through learning
                                                using digital platforms that answer the challenges of millennial
                                                students to study anywhere,
                                                anytime and with leading-edge ICT technology. From student
                                                recruitment to teaching and learning
                                                administration processes,
                                            </p>
                                            <h5>Brain Patton</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="row">
                                    <div class="col-xl-4">
                                        <img src="../../../../assets/img/about-img.png"
                                            class="img-fluid img-thumbnail ">
                                    </div>
                                    <div class="col-xl-8 d-flex align-items-center">

                                        <div class="p-3">
                                            <h2 style="font-weight:700;">
                                                What did they say
                                            </h2>
                                            <p class="samvaad-txt " style="color: #4D4D4D;">
                                                Higher education in the era of the industrial revolution 4.0
                                                requires break through learning
                                                using digital platforms that answer the challenges of millennial
                                                students to study anywhere,
                                                anytime and with leading-edge ICT technology. From student
                                                recruitment to teaching and learning
                                                administration processes,
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div> -->




                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>