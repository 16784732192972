import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { ChatService } from 'src/app/services/chat.service';
import { environment } from 'src/environments/environment';
declare var $: any;
declare const closeAllSockets: any;
declare function googleTranslate(): any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userName: string = localStorage.getItem('Username');
  roleName :string =localStorage.getItem('RoleName');
  table: Array<any> = [];
  viewMsg: Array<any> = [];
  message: Array<any> = [];
  levelUpdate: any;
  notifications: Array<any> = [];
  notification: Array<any> = [];
  viewNoti: Array<any> = [];
  OPERATION: any;
  ROLEID = localStorage.getItem('RoleId');
  myForm: FormGroup;
  oldPassword: any;
  chatMessage: any = ''
  chatMessagesList: any = [];
  differnceTime: any;
  startTime: any = new Date();
  endtime: any = new Date();
  patient_id: any;
  existingsname: any;
  studentArray: any[];
  student_id: any;
  property: string;
  TeachersCount: any;
  onlineTeachers: any;
  constructor(private route: Router, private chatService: ChatService, private CommonService: CommonService, private fb: FormBuilder, private toastr: ToastrService) {

    this.loadMessage();
    this.loadNotification();
    this.getOldPassword();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      oldPassword: ['', [Validators.required, Validators.minLength(6)]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
    })
    // googleTranslate();
    this.getOnlineTeachers(+localStorage.getItem('TenantCode'));
    setTimeout(() => {

      $(".menu-toggle").click(function (e) {
        // alert("d")
        var left: any = document.getElementsByClassName('topbar');
        left = left[0].style.left;
        e.preventDefault();
        $(".wrapper").toggleClass("toggled");
        (left == "250px" || left == "") ? $(".page-wrapper").css({ "left": "70px" }) : $(".page-wrapper").css({ "left": "250px" });

        (left == "250px" || left == "") ? $(".topbar").css({ "left": "70px" }) : $(".topbar").css({ "left": "250px" });
      })
    }, 100);

  }

  Signout() {
    this.timeTracker();
    this.chatService.setMessages(null);
    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();
      closeAllSockets();
      this.chatService.closeWebSocket();
      this.route.navigate(['/tutorHome']);
    }, 500);


  }
  menuClick() {
    $('#sidebar').toggleClass('slide');
  }
  loadMessage() {
    this.notifications = [];
    if (this.ROLEID == '1') {
      this.OPERATION = 'GETNOTIFICATIONS'
    } else if (this.ROLEID == '2') {
      this.OPERATION = 'TRAINERNOTIFICATIONS'
    } else if (this.ROLEID == '3') {
      this.OPERATION = 'STUDENTNOTIFICATIONS'
    }
    return
    this.CommonService.getCall(`Notification/GetNotification/${this.OPERATION}/${localStorage.getItem('TenantCode')}/${localStorage.getItem('UserId')}`).subscribe((res: any) => {
      // this.table = [];
      this.table = res;
    }, e => { console.log(e) })
  }

  loadNotification() {
    this.notification = [];
    this.CommonService.getCall(`EmsAlerts/GetNotificationsByUser/${localStorage.getItem('UserId')}`).subscribe((res: any) => {
      this.notification = res;
    })
  }

  viewNotification(event: any) {
    this.viewNoti = [];
    this.viewNoti = +event == 0 ? this.notification : this.notification.filter(e => e.internal_notification_id == event);

    this.CommonService.getCall(`EmsAlerts/UpdateNotificationreadStatus/${event}/${1}`).subscribe((res: any) => {

    })
  }

  // get online Teachers 27-04-2023
  getOnlineTeachers(TenantCode: number) {
    [this.TeachersCount, this.onlineTeachers] = [0, []];
    // this.CommonService.getData(`${this.WebSocket_URL}telecon/doctorstatus/getall/?TENANT_ID=${TenantCode}`).subscribe(res => {
    this.CommonService.getData(`telecon/doctorstatus/getall/?TENANT_ID=${TenantCode}`).subscribe(res => {
      [this.onlineTeachers, this.TeachersCount] = [res, res.length];
    });
  }
  // end of get online Teachers 
  viewMessage(event: any) {
    this.viewNoti = [];
    this.viewNoti = +event == 0 ? this.notification : this.notification.filter(e => e.ID == event);
  }

  delete(id: any, type: any, notificationid: any) {
    if (id) {
      type = 2;
    }
    else if (notificationid) {
      type = 1;
    }
    return
    this.CommonService.getCall(`Notification/DeleteNotification/${id}/${type}`).subscribe((res: any) => {

    })
  }

  close() {
    this.getUnViewdNotificationCount();
    // window.location.reload();
  }

  close1() {
    this.myForm.reset();
  }

  getOldPassword() {
    this.CommonService.postCall(`Registration/GetOldPassWord`, { "userid": localStorage.getItem('UserId') }).subscribe((res: any) => {
      this.oldPassword = res.password;
    })
  }


  passwordCheck() {
    let pControl = this.myForm.controls['password'];
    let cControl = this.myForm.controls['confirmPassword'];
    if (!pControl.value) {
      this.toastr.warning('Please enter the password');
      cControl.setValue(null);
      return;
    }
    if (pControl.value != cControl.value) {
      this.toastr.warning('New Password and Confirm Password Should be same');
      cControl.setValue(null);
    }
  }

  ChangePass(form: any) {
    let value = form.value;
    let payload = {
      "userid": localStorage.getItem('UserId'),
      "password": value.password
    }
    if (this.oldPassword == value.oldPassword) {
      this.CommonService.postCall(`Registration/UpdateUserPassWord`, payload).subscribe((res: any) => {
        this.toastr.success("Password Changed Successfully");
        document.getElementById('passPopupClose').click();
        this.Signout();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Password Not Changed');
      })
    } else {
      this.toastr.warning('Old Password is Incorrect');
    }
  }

  getUnViewdNotificationCount1() {
    return this.table.filter(m => m.IsRead == false).length;
  }

  getUnViewdNotificationCount() {
    return this.notification.filter(m => m.notification_read_status == 0).length;
  }


  apply() {
    document.getElementById('Noti_close')?.click();
  }

  userManual() {
    https://openuni.samvaadpro.com/home/userManual/Student_Process_Flow.docx
    window.open(`${environment.videoUrl}/home/userManual/Student_Process_Flow.docx`);
    // let adminUrl = `${location.origin}/home/userManual/Student_Process_Flow.docx`;
    // let trainerUrl = `${location.origin}/home/userManual/Student_Process_Flow.docx`;
    // let memberUrl = `${location.origin}/home/userManual/Student_Process_Flow.docx`;
    // if (this.ROLEID == '1') {
    //   window.open(adminUrl, '_blank');
    // } else if (this.ROLEID == '2') {
    //   window.open(trainerUrl, '_blank');
    // } else if (this.ROLEID == '3') {
    //   window.open(memberUrl, '_blank');
    // } else {
    //   this.toastr.warning('There is no user manual');
    // }
  }

  profileNavigate() {
    let id = localStorage.getItem('UserId');
    sessionStorage.isTabsDisabled = false;
    this.CommonService.postCall('EditRegistrationByUserId', { CREATEDBY: id }).subscribe(
      (res: any) => {
        this.route.navigate(['HOME/registration/editUserRegistration'], { queryParams: { token: res.value, wId: 64, rId: 2071 } })
      }, err => {
      }
    )

  }

  viewMessages(event: any) { }
  viewNotifications(event: any) { }

  timeTracker() {
    this.startTime = localStorage.getItem('loginTime');
    let Sdate: any = new Date(this.startTime);
    const timestamp = Sdate.toLocaleTimeString();

    this.endtime = new Date();
    this.getDataDiff(Sdate, this.endtime);

    let payload =
    {
      "loginaudit_userid": localStorage.getItem('UserId'),
      "loginaudit_login_intime": timestamp,
      "loginaudit_login_outtime": this.endtime.toLocaleTimeString(),
      "loginaudit_login_timespent": (`${this.differnceTime.hour}:${this.differnceTime.minute}:${this.differnceTime.second}`),
      "loginaudit_createdby": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('LoginAudit/CreateAudit', payload).subscribe((res: any) => { })
  }


  getDataDiff(startDate: any, endDate: any) {
    let diff = endDate.getTime() - startDate.getTime();
    let days = Math.floor(diff / (60 * 60 * 24 * 1000));
    let hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    let minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    let seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    this.differnceTime = { day: days, hour: hours, minute: minutes, second: seconds };
  }


  //chat implementation 

  // sendMessage() {
  //   this.chatMessagesList.push(this.chatMessage);
  //   this.chatMessage = '';
  // }



}
