import { environment } from 'src/environments/environment';
import { WindowRefService } from './../../services/window-ref.service';
import { ICourse } from '../../components/Shared/models/course';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription, timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-view-course-details',
  templateUrl: './view-course-details.component.html',
  styleUrls: ['./view-course-details.component.css']
})
export class ViewCourseDetailsComponent implements OnInit, OnDestroy {
  courseDetails: any;
  chapterList: Array<any> = [];
  sessionList: Array<ISessionDetails> = [];
  sessionDetails: ISessionDetails;
  myCourses: Array<any> = [];
  enroll: any;
  // myForm: FormGroup;
  private readonly onDestroy = new Subscription();
  isLogin: boolean = false;
  isAlreadyCourseTaken: boolean = false;
  isCourseExpired: boolean = false;
  // isRegistrationEnd : Boolean =false;
  // COURSESHD_ID: number = 0;
  paymentstatus: boolean = false;
  hjfdh: Array<any> = [{ id: 34 }, { id: 2 }]
  InstallmentList: Array<any> = [];
  isInstallment: boolean = false;
  mpesaRes: any;
  timerSubscription: Subscription;
  mobileNumber: string = '';
  courseFee: number = 1;
  paymentRes: any = {};
  paymentAPi: Subscription;
  clearPayment: any = null;
  courseYear: Array<any> = [];
  semesterList: Array<any> = [];
  subjects: Array<any> = [];
  chapters: Array<any> = [];
  constructor(private winRef: WindowRefService,
    private location: Location, private CommonService: CommonService, private toastr: ToastrService, private route: Router) {
    this.setDefaultCourse();
    this.setDefaultSession();
    if (+(sessionStorage.RoleId || 0) > 0) {
      this.isLogin = true;
    }
  }


  ngOnInit(): void {
    const resultState: unknown = this.location.getState();
    delete resultState['navigationId'];
    // console.log(resultState);
    this.courseDetails = resultState;
    if (this.courseDetails && !this.isLogin)
      sessionStorage.courseDetails = JSON.stringify(this.courseDetails);
    else
      sessionStorage.removeItem('courseDetails');
    // this.courseDetails = Object.values(resultState) ;
    // console.log(this.courseDetails);
    if (this.courseDetails.moreoptions)
      this.courseDetails.moreoptions = this.courseDetails.moreoptions.split(",");
    else
      this.courseDetails.moreoptions = [];

    const courseId: number = this.courseDetails.COURSE_ID || 0;
    if (courseId > 0)
      this.loadSessions(courseId);
    this.getCourses();
  }

  ngOnDestroy(): void {
    // if (this.paymentAPi) {
    // this.paymentAPi.unsubscribe();
    // }
    this.PaymentClear();
  }

  enableOrDisabledSpinner(flag: boolean = true) {
    flag ? this.CommonService.activateSpinner() : this.CommonService.deactivateSpinner();
  }

  loadSessions(courseId: number) {
    this.sessionList = [];
    this.enableOrDisabledSpinner();
    const ob1$ = this.CommonService.getCall('GetSessionsByCourseId', `/${courseId}`).subscribe((res: any) => {
      this.sessionList = res.dtCourseScehdule;
      if (this.sessionList.length > 0) {
        this.sessionDetails = { ...this.sessionList[0], Total_Payable_Amount: 0 };
        this.sessionChanged(this.sessionDetails.COURSESHD_ID);
      }
      this.enableOrDisabledSpinner(false);
    }, e => { this.enableOrDisabledSpinner(false); });
    this.onDestroy.add(ob1$);
  }

  setDefaultCourse() {
    this.courseDetails = {
      COURSE_ID: 0,
      COURSE_NAME: '',
      COURSE_DESCRIPTION: '',
      COURSE_CATEGORY_ID: 0,
      COURSE_STATUS: true,
      COURSE_RATING: 0,
      COURSE_IMAGE: '',
      COURSE_FEES: 0,
      IMAGE_URL: '',
      USERID: 0,
      FIRSTNAME: '',
      LASTNAME: '',
      COURSE_TRENDING: true,
      COURSESHD_AMOUNT: 0,
      moreoptions: []
    };
  }

  setDefaultSession() {
    this.sessionDetails = {
      COURSESHD_AMOUNT: 0,
      COURSESHD_COURSE_ID: 0,
      COURSESHD_ENDDATE: '',
      COURSESHD_ENDTIME: '',
      COURSE_FEES: 0,
      COURSESHD_ID: 0,
      COURSESHD_NAME: '',
      COURSESHD_STARTDATE: '',
      REGISTRATION_STARTDATE: '',
      REGISTRATION_ENDDATE: '',
      COURSESHD_STARTTIME: '',
      COURSESHD_PAYMENT_METHOD: 0,
      Total_Payable_Amount: 0,

    };
  }

  sessionChanged(id: number) {
    this.isInstallment = false;
    if (id > 0) {
      this.sessionDetails = { ... this.sessionList.find(e => e.COURSESHD_ID == id), Total_Payable_Amount: 0 };
      this.loadChapters(this.sessionDetails.COURSESHD_ID, this.sessionDetails.COURSESHD_COURSE_ID);
      // this.loadsemester();
      this.isInstallment = +this.sessionDetails.COURSESHD_PAYMENT_METHOD == 2;
      if (this.isInstallment)
        this.loadInstallmentDetails(+this.sessionDetails.COURSESHD_ID, +sessionStorage.UserId);
      const dt1 = new Date();
      const currentDate: string = `${dt1.getFullYear()}-${dt1.getMonth() + 1}-${dt1.getDate()}`;
      this.isCourseExpired = 0 <= this.compareDates(currentDate, this.sessionDetails.REGISTRATION_ENDDATE.replace("T00:00:00", ""));
      // this.isRegistrationEnd = 0 <= this.compareDates(currentDate, this.sessionDetails.REGISTRATION_ENDDATE?.replace("T00:00:00", ""));
      this.isExpired();
    }
    else {
      this.setDefaultSession();
      this.chapterList = [];
    }
  }

  installmentChanged(checkedItem: any, installmentItem: any) {
    if (installmentItem) {
      // installmentItem.status = checkedItem.checked;
      // if (checkedItem.checked)
      //   this.sessionDetails.Total_Payable_Amount += installmentItem.TERM_AMOUNT;
      // else
      //   this.sessionDetails.Total_Payable_Amount -= installmentItem.TERM_AMOUNT;

      if (checkedItem.checked == false) {
        this.InstallmentList.filter(e => e.CSI_INSTALMENT_ID >= installmentItem.CSI_INSTALMENT_ID).forEach(e => {
          if (e.status) {
            this.sessionDetails.Total_Payable_Amount -= e.TERM_AMOUNT;
            e.status = false;
          }
        });
      }
      else if (checkedItem.checked) {
        this.sessionDetails.Total_Payable_Amount = 0;
        this.InstallmentList.filter(e => e.CSI_INSTALMENT_ID <= installmentItem.CSI_INSTALMENT_ID).forEach(e => {
          this.sessionDetails.Total_Payable_Amount += e.TERM_AMOUNT;
          e.status = true;
        });
      }

      console.log(this.InstallmentList);
    }
  }

  loadInstallmentDetails(COURSESHD_ID: number, STUDENT_ID: number) {
    this.InstallmentList = [];
    this.enableOrDisabledSpinner();
    const ob1$ = this.CommonService.getCall('CourseSchedule/GetUpaidInstallments', `/${COURSESHD_ID}/${STUDENT_ID}`).subscribe((res: any) => {
      this.InstallmentList = res.dtCourseScehdule.map((e, index) => ({ ...e, status: !index }));//res.dtCourseScehdule;
      this.sessionDetails.Total_Payable_Amount += this.InstallmentList.length > 0 ? this.InstallmentList[0].TERM_AMOUNT : 0;
      console.log(this.InstallmentList);
      this.enableOrDisabledSpinner(false);
    }, e => { this.enableOrDisabledSpinner(false); });
    this.onDestroy.add(ob1$);
  }

  loadChapters(COURSESHD_ID: number, COURSE_ID: number) {
    this.courseYear = [];
    this.enableOrDisabledSpinner();
    // const ob1$ = this.CommonService.getCall('getChaptersByCourseId', `/${COURSESHD_ID}/${COURSE_ID}`).subscribe((res: any) => {
    const ob1$ = this.CommonService.postCall('Courses/LoadChaptersByCourse', { "COURSE_ID": COURSE_ID }).subscribe((res: any) => {
      // this.chapterList = res.map(e => ({ ...e, CHAPTER_DESCRIPTION: e.CHAPTER_DESCRIPTION?.replace("\n", "<br>") }));
      // console.log(this.chapterList);
      // this.courseYear = this.chapterList[0].dlc_LoadAcademic;
      // console.log(this.chapterList[0].dlc_LoadAcademic);
      // console.log(this.chapterList[0].dlc_LoadAcademic.map(obj => obj.Load_semesters));
      this.courseYear = res.dlc_LoadAcademic;



      this.enableOrDisabledSpinner(false);
    }, e => { this.enableOrDisabledSpinner(false); });
    this.onDestroy.add(ob1$);
  }
  loadsemester() {
    // this.semesterList = this.courseYear[0]?.Load_semesters;
  }
  viewSemester(cYearId: any) {
    // if (!cYearId) {
    //   this.loadsemester();
    // }

    this.semesterList = [];
    let SelectedYaer = this.courseYear.find((e: any) => e.COURSESHD_COURSEYEAR == cYearId);
    this.semesterList = SelectedYaer.Load_semesters
  }
  viewSubjects(semId: any) {
    this.subjects = [];
    let selectedSemester = this.semesterList.find((e: any) => e.COURSESHD_SEMESTER == semId);
    this.subjects = selectedSemester.Load_Subjects;
  }
  viewChapters(chapId: any) {
    this.chapters = [];
    let slectedSubject = this.subjects.find((e: any) => e.SUBJECT_ID == chapId);
    this.chapters = slectedSubject.Load_Chapters;
  }
  Enroll() {
    if (this.isLogin)
      document.getElementById('btnEnrolled').click();
    else
      this.route.navigate(['/login']).then(() => {
        this.toastr.warning("Please login!");
      });
  }

  isEnrolled(details: any): boolean {
    // const index = this.myCourses?.findIndex(m => { m.COURSE_ID == details.COURSE_ID && m.COURSESHD_ID == details.COURSESHD_ID });
    const index = this.myCourses?.findIndex(m => { m.COURSE_ID == details.COURSE_ID });
    return index > 0;

  }

  EnrollSuccess() {
    const { UserId, TenantCode } = sessionStorage;
    if (!UserId) {
      this.route.navigate(['/login']);
    } else {
      let payLoad = [];
      if (this.isInstallment && this.InstallmentList.length > 0) {
        payLoad = this.InstallmentList.filter(f => f.status == true).map(e =>
        ({
          CourseId: this.sessionDetails.COURSESHD_COURSE_ID,
          CREATEDBY: UserId,
          TNT_CODE: TenantCode,
          UserId: UserId,
          CourseScheduleId: this.sessionDetails.COURSESHD_ID,
          ModifiedBy: UserId,
          STUDENT_PAYMENT_COURSE_FEE: this.sessionDetails.COURSESHD_AMOUNT,
          STUDENT_PAYMENT_TOTAL_AMOUNT_PAID: e.TERM_AMOUNT,
          STUDENT_PAYMENT_CSI_INSTALLMENT_ID: e.CSI_INSTALMENT_ID
        }));
        if (payLoad.length == 0) {
          this.toastr.warning('Please select at least one installment');
          return;
        }
      }
      else {
        payLoad = [{
          CourseId: this.sessionDetails.COURSESHD_COURSE_ID,
          CREATEDBY: UserId,
          TNT_CODE: TenantCode,
          UserId: UserId,
          CourseScheduleId: this.sessionDetails.COURSESHD_ID,
          ModifiedBy: UserId,
          STUDENT_PAYMENT_COURSE_FEE: this.sessionDetails.COURSESHD_AMOUNT,
          STUDENT_PAYMENT_TOTAL_AMOUNT_PAID: this.sessionDetails.COURSESHD_AMOUNT,
          STUDENT_PAYMENT_CSI_INSTALLMENT_ID: 0
        }];
      }

      // return;
      this.CommonService.postCall('Enroll/CreateEnroll', payLoad).subscribe((res: any) => {
        // this.enroll = res;
        if (res) {
          document.getElementById('md_close')?.click();
          this.toastr.success('Payment Successfully');
          // this.ngOnDestroy();
          this.PaymentClear();

          const { student_payment_id } = res.STUDENT_PAYMENT_ID;
          // this.make_Payment(student_payment_id);
        }
        // document.getElementById('md_close').click();
        // this.toastr.success('Payment Successfully');
        // this.route.navigate(['HOME/my-courses']);

      });

    }
  }

  onPaymentClicked() {
    if (this.mobileNumber == "") {
      return this.toastr.warning("Please enter mobile number")
    } else {
      if (this.isInstallment) {
        if (this.InstallmentList.length == 0) {
          document.getElementById('md_close').click();
          this.toastr.error('You have already taken this course');
          return;
        }
        const isSelectedInstallments: boolean = this.InstallmentList.some(e => e.status == true);
        if (isSelectedInstallments == false) {
          this.toastr.error('Please select at least one installment.');
          return;
        }
        this.mpesaPayment();

        // this.timerSubscription = timer(0, 10000).pipe(
        //   map(() => {
        //     this.paymentConformation(); 
        //   })
        // ).subscribe();


      }
      else
        this.IsPaidforSchedule();
    }

  }

  mpesaPayment() {
    this.EnrollSuccess()
    // localStorage.getItem('CourseFee')
    // let payload = {
    //   MobileNumber: this.mobileNumber,
    //   Amount: "1"
    // }
    // this.CommonService.postCall('MPesaIntegration/MpesaPush', payload).subscribe((res: any) => {
    //   this.mpesaRes = res.checkoutId;
    //   this.clearPayment = setInterval(() => this.paymentConformation(), 10000);
    // });

  }

  PaymentClear() {
    clearInterval(this.clearPayment);
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }
  deactiveSpinner() {
    this.CommonService.deactivateSpinner();
  }
  paymentConformation() {
    let count = 0;
    count++;
    this.paymentAPi = this.CommonService.getCall(`MPesaIntegration/ConfirmationBooking/${this.mpesaRes}`).subscribe((res: any) => {
      this.paymentRes = res.status;
      if (this.paymentRes == 1) {
        this.EnrollSuccess();
      } else if (this.paymentRes == 2) {
        this.toastr.warning('Payment Cancelled');
        setTimeout(() => {
          // this.ngOnDestroy();
          this.PaymentClear();
        }, 100)
      }
      else {
        this.activeSpinner();
        if (count == 5) {
          this.PaymentClear();
        }
        setTimeout(() => {
          this.deactiveSpinner();
        }, 12000);
      }
    })
  }


  IsPaidforSchedule() {
    const { UserId } = sessionStorage;
    const COURSESHD_ID: number = this.sessionDetails.COURSESHD_ID;
    if (COURSESHD_ID > 0) {
      this.enableOrDisabledSpinner();
      const Schedule$ = this.CommonService.getCall('Courses/IsPaidforSchedule/', `${UserId}/${COURSESHD_ID}`).subscribe((res: any) => {
        const isCourseTaken = res;
        if (isCourseTaken) {
          document.getElementById('md_close').click();
          this.toastr.error('You have already taken this course');
        }
        else {
          this.mpesaPayment();
        }
        this.enableOrDisabledSpinner(false);
      }, e => { this.enableOrDisabledSpinner(false); });
      this.onDestroy.add(Schedule$);
    }
    else
      this.toastr.warning('Please select a Schedule');
  }

  getCourses() {
    this.myCourses = [];
    this.enableOrDisabledSpinner();
    const mycourse$ = this.CommonService.getCall('CourseSchedule/GetMyCourses/', `${sessionStorage.UserId}`).subscribe((res: any) => {
      this.myCourses = res.dtCourseScehdule;
      if (this.isLogin) {
        this.isAlreadyCourseTaken = this.isEnrolled(this.courseDetails);
      }
      this.isExpired();
      this.enableOrDisabledSpinner(false);
    }, e => { this.enableOrDisabledSpinner(false); });
    this.onDestroy.add(mycourse$);

  }

  isExpired() {
    const exp: boolean = this.sessionDetails.COURSESHD_ID > 0 && (this.isCourseExpired && (!this.isAlreadyCourseTaken));
    if (!exp) {
      this.toastr.warning('Yours Registration date is Ended' || 'Your course is Expired');
    }

  }

  close() { this.sessionDetails.Total_Payable_Amount = 0; }

  GSTPercentages(val: number) {
    const totalGST = val * 18 / 100;
    return totalGST;
  }

  make_Payment(student_payment_id: number) {
    const currentDate = new Date();
    const newDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
    currentDate.setDate(currentDate.getDate() + 10);
    const expiryDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
    const totalAmt = this.isInstallment ? this.sessionDetails.Total_Payable_Amount : this.sessionDetails.COURSESHD_AMOUNT;
    const { UserId, TenantCode } = sessionStorage;
    const payload = {
      payment_id: student_payment_id,
      user_regid: UserId,
      groupadmin_regid: 0,
      amount: totalAmt + this.GSTPercentages(totalAmt),
      payment_date: newDate,
      payment_mode: 1,
      airport_id: 1,
      course_id: 1,
      approved_tranction_details_id: 0,
      expiry_date: expiryDate,
      // tnt_code: 12345678,
      tnt_code: TenantCode,
      created_by: UserId,
      create_date: newDate
    };

    this.enableOrDisabledSpinner();
    const mycourse$ = this.CommonService.postCall('Payment/MakePayment/', payload).subscribe((res: any) => {
      this.payWithRazor(res);
      this.enableOrDisabledSpinner(false);
    }, e => { this.enableOrDisabledSpinner(false); });
    this.onDestroy.add(mycourse$);

  }

  payWithRazor(val: any) {
    const options: any = {
      prefill: {
        email: sessionStorage.USERNAME,
        contact: sessionStorage.MobileNo
      },
      key: environment.RazorPay_Key,
      // key: 'rzp_test_n9kOog8GmBqfim',
      // amount: val.amount, // amount should be in paise format to display Rs 1255 without decimal point
      amount: 1, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: 'shiksion', // company name or product name
      description: '',  // product description
      image: './../../../assets/img/samvaad_tutor_logo.png', // company logo or product image
      order_id: val.order_id,//'order_K4gst603mrTngY', // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      }
    };

    options.handler = ((response: any, error: any) => {
      options.response = response;
      console.log(response);
      console.log(options);
      console.log(error)
      response.json = JSON.stringify(response);
      response.payment_id = val.payment_id;
      response.created_by = sessionStorage.UserId,
        this.paymentResponse(response);
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.on('payment.failed', function (respons: any) {
      console.log(respons);
      alert("This step of Payment Failed");
    });
    rzp.open();
  }

  paymentResponse(data: any) {
    this.CommonService.postCall('Payment/confirm', data).subscribe((res: any) => {
      setTimeout(() => {
        this.paymentstatus = true;
        // setTimeout(() => { this.route.navigate(['/']) }, 30000);
        document.getElementById('md_close').click();
        this.toastr.success('Payment Successfully');
        this.route.navigate(['HOME/my-courses']);

      }, 2000);

      // this.cloasewindow();

    }, e => {

    })

  }

  compareDates(d1: string, d2: string): number {
    const date1 = new Date(d1).getTime();
    const date2 = new Date(d2).getTime();
    let returnval = 0;
    if (date1 < date2) {
      console.log(`${d1} is less than ${d2}`);
      returnval = 1;
    } else if (date1 > date2) {
      console.log(`${d1} is greater than ${d2}`);
      returnval = -1;
    } else {
      console.log(`Both dates are equal`);
      returnval = 0;
    }
    return returnval;
  }
}

interface ISessionDetails {
  COURSESHD_AMOUNT: number;
  COURSESHD_COURSE_ID: number;
  COURSESHD_ENDDATE: string;
  COURSESHD_ENDTIME: string;
  COURSESHD_ID: number;
  COURSESHD_NAME: string;
  COURSE_FEES: number;
  COURSESHD_STARTDATE: string;
  COURSESHD_STARTTIME: string;
  REGISTRATION_STARTDATE: string;
  REGISTRATION_ENDDATE: string;
  COURSESHD_PAYMENT_METHOD: number;
  Total_Payable_Amount: number;
}
