<footer>
    <div class="container-fluid ">
        <div class="row p-5 bg-footer">
            
            <div class="col-xl-9 col-md-6">
                <h5>About The Open University Of Kenya</h5>
                <ul class="footer-links">
                    <!-- <li><a [routerLink]="'/eRP/about-us'">About Us</a></li>
                    <li><a [routerLink]="'/eRP/contact-us'">Contact Us</a></li>
                    <li><a [routerLink]="'/eRP/terms-conditions'">Terms and Conditions</a></li>
                    <li><a [routerLink]="'/eRP/refund-policy'">Refund Policy</a></li>
                    <li><a [routerLink]="'/eRP/privacy-policy'">Privacy Policy</a></li> -->
                </ul>
            </div>
            <div class="col-xl-3 col-md-6">
                <h5>Enquiries</h5>
                <ul class="contact-links">
                    <!-- <li><a href="#" class="text-white">info@samvaadpro.com</a></li>
                    <li><a href="#" class="text-white">(+91) 4049135000</a></li> -->
                    <li>
                        <hr class="m-0 mb-2">
                        <div>
                            <form class="well form-horizontal" [formGroup]="myForm" (onSubmit)="onSubmitForm(myForm)">
                                <div class="mb-2">
                                    <input type="text" class="form-control" id="exampleFormControlInput1"
                                        formControlName="CONTACT_NAME" placeholder="Name"
                                        onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))'>
                                </div>
                                <div class="mb-2">
                                    <input type="email" class="form-control" id="txtemail" email="true"
                                        formControlName="CONTACT_EMAILID" placeholder="name@example.com"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                        (change)="validateEmail($event.target.value)">
                                </div>
                                <div class="mb-2">
                                    <textarea class="form-control" id="exampleFormControlTextarea1"
                                        formControlName="CONTACT_DESCRIPTION" placeholder="Enter Description"
                                        rows="2"></textarea>
                                </div>
                                <div class="">
                                    <button type="button" (click)="onSubmitForm(myForm)"
                                        class="btn btn-sm btn-style-1">Submit</button>
                                </div>
                            </form>
                        </div>
                    </li>
                    <!-- <li class="social-icons">
                        <ul class="list-group list-group-horizontal">
                            <li class="list-group-item p-0 pe-3"><a href="#"><i class="fab fa-instagram"></i></a>
                            </li>
                            <li class="list-group-item p-0 pe-3"><a href="#"><i class="fab fa-twitter"></i></a></li>

                            <li class="list-group-item p-0 pe-3"><a href="#"><i class="fab fa-linkedin-in"></i></a>
                            </li>
                            <li class="list-group-item p-0 pe-3"><a href="#"><i class="fab fa-youtube"></i></a></li>
                        </ul>
                    </li> -->


                </ul>
            </div>
        </div>

        <app-main-footer></app-main-footer>

    </div>

</footer>